<template>
  <a-card :bordered="false" size="small" style="height: 240px">
    <template slot="title">库存占比</template>
    <div ref="pieChart"></div>
  </a-card>
</template>

<script>
import { inventoryProportion } from "@/api/stats";
import { Pie } from "@antv/g2plot";

export default {
  data() {
    return {
      pieChart: null,
    };
  },
  methods: {
    initData() {
      this.pieChart = new Pie(this.$refs.pieChart, {
        data: [],
        angleField: "total_amount",
        colorField: "category",
        radius: 0.9,
        label: {
          type: "inner",
          offset: "-30%",
          content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
          style: {
            fontSize: 14,
            textAlign: "center",
          },
        },
        interactions: [{ type: "element-active" }],
        height: 160,
      });

      this.pieChart.render();

      this.getData();
    },
    getData() {
      inventoryProportion().then((data) => {
        this.pieChart.changeData(data);
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
